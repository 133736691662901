<template>
  <crud-form
    :schema="schema"
    :path="'page'"
    :model="form"
    :form-title="$t('addPage')"
    :redirect-route="'PagesList'"
  />
</template>

<script>
  import CrudForm from "../../components/CrudForm";
  import { schema } from './schema/page';
  import { form } from './forms/page';

  export default {
    name: 'AddPage',
    components: {
      CrudForm
    },
    data: () => {
      return {
        schema,
        form
      }
    },
  }
</script>

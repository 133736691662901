import {validators} from "vue-form-generator";
import i18n from "@/plugins/i18n";
import { uniquePageUrlValidator } from "../validations/uniquePageUrl";

export const schema = {
  fields: [
    {
      type: "input",
      inputType: "text",
      label: "name",
      model: "title",
      required: true,
      translation: true,
      placeholder: "name",
      max: 256,
      validator: [
        validators.string.locale({
          fieldIsRequired: i18n.t('nameRequired'),
          textTooSmall: i18n.t('nameMustBeMin{1}'),
          textTooBig: i18n.t('nameMustBeLess{1}'),
        }),
      ]
    },
    {
      type: "input",
      inputType: "text",
      label: "url",
      model: "url",
      placeholder: "url",
      max: 256,
      validator: [
        uniquePageUrlValidator,
        validators.string.locale({
          textTooSmall: i18n.t('urlMustBeMin{1}'),
          textTooBig: i18n.t('urlMustBeLess{1}'),
        }),
      ]
    },
    {
      type: "editor",
      label: "content",
      model: "content",
      required: true,
      translation: true,
      placeholder: "content",
      validator: [
        validators.string.locale({
          fieldIsRequired: i18n.t("contentRequired"),
        }),
      ]
    },
    {
      type: "datepicker",
      label: "activateFrom",
      model: "activateFrom",
      required: false,
      placeholder: "activateTo",
      validator: [
        validators.date.locale({
          invalidDate: i18n.t("invalidDate"),
        }),
      ]
    },
    {
      type: "datepicker",
      label: "activateTo",
      model: "activateTo",
      required: false,
      placeholder: "activateTo",
      validator: [
        validators.date.locale({
          invalidDate: i18n.t("invalidDate"),
        }),
      ]
    },
    {
      type: "textArea",
      rows: 3,
      label: "metaTitle",
      model: "metaTitle",
      required: false,
      placeholder: "metaTitle",
    },
    {
      type: "textArea",
      rows: 3,
      label: "metaDescription",
      model: "metaDescription",
      required: false,
      placeholder: "metaDescription",
    },
    {
      type: "textArea",
      rows: 3,
      label: "metaKeywords",
      model: "metaKeywords",
      required: false,
      placeholder: "metaKeywords",
    },
    {
      type: "select",
      label: "status",
      model: "status",
      required: true,
      validator: [
        validators.required.locale({
          fieldIsRequired: i18n.t("statusRequired"),
        }),
      ],
      selectOptions: {
        noneSelectedText: i18n.t("chooseStatus"),
      },
      values: [
        { name: i18n.t("Active"), id: 1 },
        { name: i18n.t("Hidden"), id: 0 },
      ],
    },
  ]
};
